import React from "react";
import styled from "styled-components";
import NavItem from "./NavItem";
import useCopy from "../../hooks/useCopy";
import { useAppStateContext } from "../../providers/AppStateProvider";
import { datadogEvent } from "../../datadog";

const ItemText = styled.div`
  display: inline-block;
  margin-top: 2px;
`;

const Counter = styled.div`
  position: absolute;
  display: inline-block;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: white;
  line-height: 20px;
  text-align: center;
`;

const NavMenu = ({ activePage }) => {
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants: waitingParticipants } = waitingRoom;
  const [waitingRoomText, historyText, settingsText, helpText] = useCopy([
    "navItem.waitingRoom",
    "navItem.sessionHistory",
    "navItem.settings",
    "navItem.help",
  ]);

  const itemClicked = featureName => {
    datadogEvent({
      category: "navigation",
      feature: featureName,
      event: "clicked",
      component: "SidebarLayout.Menu",
    });
  };

  return (
    <>
      <NavItem
        active={activePage === "/dashboard"}
        text={
          activePage !== "/dashboard" && waitingParticipants.length > 0 ? (
            <>
              <ItemText>{waitingRoomText}</ItemText>
              <Counter>{waitingParticipants.length}</Counter>
            </>
          ) : (
            waitingRoomText
          )
        }
        path="/dashboard"
        onClick={() => itemClicked("dashboard")}
      />
      <NavItem
        active={activePage === "/dashboard/history"}
        text={historyText}
        path="/dashboard/history"
        onClick={() => itemClicked("session_history")}
      />
      <NavItem
        active={activePage === "/dashboard/settings"}
        text={settingsText}
        path="/dashboard/settings"
        onClick={() => itemClicked("room_settings")}
      />
      <NavItem
        active={activePage === "/dashboard/help"}
        text={helpText}
        path="/dashboard/help"
        onClick={() => itemClicked("help")}
      />
    </>
  );
};

export default NavMenu;
