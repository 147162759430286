import React from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import ClientPicture from "../ClientPicture";
import StartButton from "./StartButton";
import ChatButton from "./ChatButton";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import { WaitingRoomChat } from "../Chat";
import { datadogEvent } from "../../datadog";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.light};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px;
  box-sizing: border-box;
  overflow: hidden;
  button {
    height: 30px;
  }
`;

const ClientPictureWrapper = styled.div`
  margin-right: 10px;
  flex: 0 0 auto;
  line-height: 0;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

const ClientName = styled.div`
  margin: 5px 10px 5px 0;
  word-break: break-word;
  flex: 1 0 auto;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.2;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.normal};
  }
`;

const ButtonsWrapper = styled.div`
  flex: 0 0 auto;
`;

export default function ClientItem({
  participant,
  onStart,
  startDisabled,
  buttonText,
  loading,
  chatDisabled,
  isGhost,
  index,
}) {
  const showButtons = !isGhost;
  const [genericUserText] = useCopy(["clientlist.genericUser"]);
  const {
    photoUrl,
    displayName,
    status: participantInfoStatus,
  } = useParticipantInfo(participant.participantId);

  let name = participant.displayName ? participant.displayName : displayName;

  if (participantInfoStatus === "error") {
    name = swapCopyVariables(genericUserText, { NUMBER: index + 1 });
  }

  const startSession = componentName => {
    datadogEvent({
      category: "session",
      feature: "start",
      event: "clicked",
      component: `TherapistVideoPanel.ClientItem.${componentName}`,
    });

    onStart();
  };

  return (
    <Wrapper>
      <ClientPictureWrapper>
        <ClientPicture photo={photoUrl} />
      </ClientPictureWrapper>
      <NameWrapper>
        <ClientName>{name}</ClientName>
        {showButtons && (
          <ButtonsWrapper>
            <ChatButton
              participant={participant}
              channel={participant.participantId}
              disabled={chatDisabled}
            />
            <WaitingRoomChat
              isTherapist={true}
              participant={participant}
              channel={participant.participantId}
              onAdd={() => startSession("WaitingRoomChat")}
              position="right"
              overlap={true}
              addDisabled={startDisabled}
            />
            <StartButton
              participantId={participant.participantId}
              onClick={() => startSession("StartButton")}
              disabled={startDisabled}
              buttonText={buttonText}
              loading={loading}
            />
          </ButtonsWrapper>
        )}
      </NameWrapper>
    </Wrapper>
  );
}
