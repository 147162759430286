import React from "react";
import styled from "styled-components";
import MobileNav from "./MobileNav";
import DesktopNav from "./DesktopNav";
import AccountMenu from "./AccountMenu";
import TherapistInfo from "./TherapistInfo";
import ptLogo from "../../assets/logo.svg";
import sessionsLogo from "../../assets/pt-sessions-logo-lg.svg";
import { datadogEvent } from "../../datadog";

const Wrapper = styled.header`
  height: auto;
  background: ${({ theme }) => theme.header.background};
  box-sizing: border-box;
  z-index: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: ${({ theme }) => theme.header.height};
    position: sticky;
    top: 0;
    margin-bottom: 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    pointer-events: none;
    & button,
    & a {
      pointer-events: auto;
    }
  }
`;

const ContentWrapper = styled.div`
  width: calc(100% - 35px);
  padding: 5px 15px 5px 20px;
  margin-bottom: 25px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
  }
`;

const Logo = styled.img`
  display: block;
  user-select: none;
`;

const PTLogo = styled(Logo)`
  height: 30px;
  padding: 15px 0;
`;

const SessionsLogo = styled(Logo)`
  height: 42px;
  padding: 10px 0;
`;

const MobileNavWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 15px;
  display: flex;
  align-items: center;
  pointer-events: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`;

const AccountWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    position: relative;
    top: unset;
    right: unset;
  }
`;

const Header = ({ type }) => {
  const trackNavEvent = (item, component) => {
    datadogEvent({
      category: "navigation",
      feature: item.id,
      event: "clicked",
      component,
    });
  };

  const getHeaderContent = () => {
    switch (type) {
      case "therapist":
        return (
          <>
            <MobileNavWrapper>
              <MobileNav trackNavEvent={trackNavEvent} />
            </MobileNavWrapper>
            <PTLogo src={ptLogo} />
            <DesktopNav trackNavEvent={trackNavEvent} />
            <AccountWrapper>
              <AccountMenu />
            </AccountWrapper>
          </>
        );
      case "client":
        return <TherapistInfo />;
      case "logoOnly":
        return <SessionsLogo src={sessionsLogo} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Wrapper>
        <ContentWrapper>{getHeaderContent()}</ContentWrapper>
      </Wrapper>
    </>
  );
};

export default Header;
