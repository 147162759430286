import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { Link } from "@sussex/react-kit/elements";
import { datadogEvent } from "../../datadog";
import useCopy from "../../hooks/useCopy";
import closeIcon from "../../assets/close.svg";
import professionalsIcon from "../../assets/professionals-icon.png";
import sessionsIcon from "../../assets/sessions-icon.png";

const DISMISSED_FLAG = "nativeAppPromptDismissed";
const TRANSITION_TIME = 250;
const TRANSITION_NAME = "slidein";

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0px 10px ${({ theme }) => theme.colors.default};
  background-color: white;
  padding: 10px;
  gap: 10px;
  align-items: center;

  &.${TRANSITION_NAME}-enter {
    transform: translateY(100%);
  }

  &.${TRANSITION_NAME}-enter-active {
    transform: translateY(0);
    transition: transform ${TRANSITION_TIME}ms ease-in-out;
  }

  &.${TRANSITION_NAME}-exit {
    transform: translateY(0);
  }

  &.${TRANSITION_NAME}-exit-active {
    transform: translateY(100%);
    transition: transform ${TRANSITION_TIME}ms ease-in-out;
  }
`;

const AppIcon = styled.img`
  display: block;
  flex: 0;
  border-radius: 4px;
  width: 27px;
`;

const PromptLink = styled(Link)`
  flex: 1 1 auto;
  font-size: ${({ theme }) => theme.fontSize.normal};
  text-align: left;
`;

const Close = styled.img`
  flex: 0;
  width: 15px;
  padding-right: 5px;
  cursor: pointer;
`;

/**
 * AppInstallBanner is a prompt to install a native application.
 * It is currently android-only. iOS is handled natively via smart banners:
 * https://developer.apple.com/documentation/webkit/promoting_apps_with_smart_app_banners
 * The native android mini-info bar conditional display was too restrictive for
 * our needs. This attempts to replicate the mini-info bar with our own
 * display conditions. See android docs for more details:
 * https://developers.google.com/web/fundamentals/app-install-banners/native
 *
 *
 * @param string app App Name, one of "professionals" or "sessions"
 */
const AppInstallBanner = ({ app }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [openPromptEvent, setOpenPromptEvent] = useState(null);
  const bannerRef = useRef(null);
  const [sessionsLinkText, professionalsLinkText] = useCopy([
    "appBanner.sessions.link",
    "appBanner.professionals.link",
  ]);

  const handleDismiss = () => {
    datadogEvent({
      category: "native_app_banner",
      event: "dismissed",
      component: "AppInstallBanner",
      metadata: {
        platform: "android",
      },
    });
    setShowBanner(false);
    localStorage.setItem(DISMISSED_FLAG, true);
  };

  const handleOpen = () => {
    datadogEvent({
      category: "native_app_banner",
      event: "opened",
      component: "AppInstallBanner",
      metadata: {
        platform: "android",
      },
    });
    openPromptEvent.prompt();
    handleDismiss();
  };

  useEffect(() => {
    // Don't do anything if the user has already dismissed the banner
    if (localStorage.getItem(DISMISSED_FLAG)) {
      return;
    }

    const handlePrompt = e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      setOpenPromptEvent(e);
      setShowBanner(true);
    };

    // If event has already been fired and caught from index.html, use that event
    if (window.androidNativeAppInstallPrompt) {
      handlePrompt(window.androidNativeAppInstallPrompt);
    }

    window.addEventListener("beforeinstallprompt", handlePrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", handlePrompt);
    };
  }, []);

  return (
    <CSSTransition
      in={showBanner}
      timeout={TRANSITION_TIME}
      classNames={TRANSITION_NAME}
      nodeRef={bannerRef}
      unmountOnExit
    >
      <Wrapper ref={bannerRef}>
        <AppIcon src={app === "sessions" ? sessionsIcon : professionalsIcon} />
        <PromptLink onClick={handleOpen}>
          {app === "sessions" ? sessionsLinkText : professionalsLinkText}
        </PromptLink>
        <Close src={closeIcon} onClick={handleDismiss}></Close>
      </Wrapper>
    </CSSTransition>
  );
};

export default AppInstallBanner;
