import React, { useEffect } from "react";
import styled from "styled-components";
import { H1, H3, H4, Anchor } from "@sussex/react-kit/elements";
import useCopy from "../hooks/useCopy";
import Panel from "../components/Panel";
import { datadogSetPage } from "../datadog";

const Header = styled.div`
  margin-bottom: 20px;
`;

const Body = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1.6;

  h3 {
    ${H3.componentStyle.rules}
    line-height: 1;
  }

  h4 {
    ${H4.componentStyle.rules}
    line-height: 1;
    margin-bottom: 10px;
  }

  p {
    margin: 20px 0;
  }

  a {
    ${Anchor.componentStyle.rules}
  }

  ul {
    padding-left: 20px;
  }

  hr {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    margin: 20px 0;
  }
`;

const Content = ({ title, body }) => {
  return (
    <>
      <Header>
        <H1>{title}</H1>
      </Header>
      <Body dangerouslySetInnerHTML={{ __html: body }} />
    </>
  );
};

const HelpView = () => {
  const [helpTitle, helpBody] = useCopy(["help.title", "help.body"]);

  useEffect(() => {
    datadogSetPage("Help");
  }, []);

  return (
    <Panel>
      <Content title={helpTitle} body={helpBody} />
    </Panel>
  );
};

export default HelpView;
