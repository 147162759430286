import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import enabledIcon from "../../../assets/active-mic-icon.svg";
import disabledIcon from "../../../assets/inactive-mic-icon.svg";
import IconButton from "./IconButton";
import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle";
import useCopy from "../../../hooks/useCopy";
import { datadogEvent } from "../../../datadog";

const EnabledIcon = styled.img`
  width: 35%;
`;

const DisabledIcon = styled.img`
  width: 52%;
`;

export default function AudioToggleButton() {
  const [isWaiting, setIsWaiting] = useState(false);
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [disableAudioText, enableAudioText] = useCopy([
    "sessioncontrols.disableAudio",
    "sessioncontrols.enableAudio",
  ]);
  const theme = useTheme();

  const onClick = () => {
    if (isWaiting) {
      return;
    }

    datadogEvent({
      category: "controls",
      feature: "microphone",
      event: isAudioEnabled ? "disabled" : "enabled",
      component: "SessionControls.Buttons.AudioToggleButton",
    });

    toggleAudioEnabled();
    setIsWaiting(true);
    setTimeout(() => setIsWaiting(false), 500);
  };

  return (
    <IconButton
      onClick={onClick}
      id="toggleAudio"
      background={!isAudioEnabled ? theme.colors.red : undefined}
      tooltip={isAudioEnabled ? disableAudioText : enableAudioText}
    >
      {isAudioEnabled ? (
        <EnabledIcon src={enabledIcon} alt={disableAudioText} />
      ) : (
        <DisabledIcon src={disabledIcon} alt={enableAudioText} />
      )}
    </IconButton>
  );
}
