import React from "react";
import styled from "styled-components";

import useCopy from "../../hooks/useCopy";

import { H2 } from "@sussex/react-kit/elements";
import { SlideoutPanel } from "../SlideoutLayout";

import {
  SelectVideoTrack,
  SelectVideoQuality,
  SelectAudioInputTrack,
  SelectAudioOutputTrack,
} from "../SessionControls/MediaSelection";

const SelectItem = styled.div`
  margin-bottom: 17px;
`;

const BodyWrapper = styled.div`
  text-align: left;
  padding: 15px 25px 0 15px;
`;

const Header = () => {
  const [title] = useCopy(["callscreen.changeInputs.title"]);
  return <H2>{title}</H2>;
};

const Body = ({ testsEnabled }) => {
  return (
    <BodyWrapper>
      <SelectItem>
        <SelectAudioInputTrack testsEnabled={testsEnabled} />
      </SelectItem>
      <SelectItem>
        <SelectAudioOutputTrack testsEnabled={testsEnabled} />
      </SelectItem>
      <SelectItem>
        <SelectVideoTrack />
      </SelectItem>
      <SelectItem>
        <SelectVideoQuality />
      </SelectItem>
    </BodyWrapper>
  );
};

export default function VideoAndMicSettings({
  active,
  onClose,
  overlap,
  testsEnabled,
}) {
  return (
    <SlideoutPanel
      active={active}
      onClose={onClose}
      header={Header}
      overlap={overlap}
      position="right"
    >
      <Body testsEnabled={testsEnabled} />
    </SlideoutPanel>
  );
}
