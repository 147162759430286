import React from "react";
import styled from "styled-components";
import { Label } from "@sussex/react-kit/elements";
import AvailableBackgrounds, { DefaultBackground } from "../../backgrounds";
import useCopy from "../../hooks/useCopy";
import { datadogEvent } from "../../datadog";

//currentBackground: url(${({ currentBackground }) =>
// '/backgrounds/thumbnails/' + currentBackground}) no-repeat;
const Background = styled.div`
  cursor: pointer;
  position: relative;
  width: calc(50% - 10px);
  margin: 5px 0;
  outline: 3px solid
    ${({ selected, theme }) => (selected ? theme.colors.green : "transparent")};
  outline-offset: -3px;

  > * {
    display: block;
    height: 100%;
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 6px 0;
    width: calc(25% - 6px);
  }
`;

const BackgroundContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
`;

const NoBackgroundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f7f9;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
  font-size: 15px;
`;

function BackgroundSettings({ currentBackground, onChange }) {
  const [displayNameLabel, noBackground] = useCopy([
    "settings.waitingRoomBackgroundLabel",
    "settings.waitingRoomNoBackground",
  ]);
  const selectedBackground = currentBackground || DefaultBackground;

  const handleClick = key => {
    datadogEvent({
      category: "room_settings",
      feature: "background_image",
      event: "clicked",
      component: "RoomSettings.BackgroundSettings",
      metadata: {
        image: key,
      },
    });

    onChange(key);
  };

  const backgroundList = Object.keys(AvailableBackgrounds).map(key => {
    return (
      <Background
        key={key}
        selected={key === selectedBackground}
        onClick={() => handleClick(key)}
      >
        <img
          src={`/backgrounds/thumbnails/${AvailableBackgrounds[key]}`}
          alt={key}
        />
      </Background>
    );
  });

  return (
    <div>
      <Label>{displayNameLabel}</Label>
      <BackgroundContainer>
        <Background
          selected={!selectedBackground}
          onClick={() => handleClick("")}
        >
          <NoBackgroundWrapper>{noBackground}</NoBackgroundWrapper>
        </Background>
        {backgroundList}
      </BackgroundContainer>
    </div>
  );
}

export default BackgroundSettings;
