import React, { useState } from "react";
import styled from "styled-components";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { H1, Button } from "@sussex/react-kit/elements";
import NameSettings from "./NameSettings";
import ClientTimerSettings from "./ClientTimerSettings";
import BAASettings from "./BAASettings";
import BackgroundSettings from "./BackgroundSettings";
import RoomSettingsNotification from "./RoomSettingsNotification";
import ConfirmationModal from "../ConfirmationModal";
import Panel from "../Panel";
import { requestUpdateRoom } from "../../httpapi";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import { isdef } from "@sussex/react-kit/utils";
import { datadogEvent } from "../../datadog";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

const Header = styled.div`
  margin-bottom: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  background: white;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 20px;
  /* Little bit of negative margin to cover up the
  settings panel's bottom border radius */
  margin-top: -16px;
`;

function RoomSettings() {
  const { room, setRoom } = useWaitingRoomContext();
  const [background, setBackground] = useState(room.background);
  const [therapistDisplayName, setTherapistDisplayName] = useState(
    room.therapistDisplayName,
  );
  const [roomAlias, setRoomAlias] = useState(room.roomAlias);
  const [oldRoomAlias, setOldRoomAlias] = useState(null);
  const [clientTimer, setClientTimer] = useState(room.clientTimer);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [
    header,
    save,
    confirmationTitle,
    confirmationBody,
    errorInUse,
    errorInvalid,
    errorInternal,
  ] = useCopy([
    "settings.header",
    "settings.save",
    "settings.confirmationTitle",
    "settings.confirmationBody",
    "setup.errorUrlInUse",
    "setup.errorInvalid",
    "setup.errorInternal",
  ]);

  const saveDisabled = () =>
    busy ||
    !therapistDisplayName ||
    !roomAlias ||
    (therapistDisplayName === room.therapistDisplayName &&
      roomAlias === room.roomAlias &&
      background === room.background &&
      clientTimer === room.clientTimer);

  const onChange = data => {
    if (error) {
      setError(null);
    }
    if (message) {
      setMessage(null);
    }
    if (isdef(data.therapistDisplayName)) {
      setTherapistDisplayName(data.therapistDisplayName);
    }
    if (isdef(data.roomAlias)) {
      setRoomAlias(data.roomAlias);
    }
    if (isdef(data.clientTimer)) {
      setClientTimer(data.clientTimer);
    }
  };

  const handleSubmit = async evt => {
    evt.preventDefault();

    datadogEvent({
      category: "room_settings",
      feature: "save",
      event: "clicked",
      component: "RoomSettings",
    });

    setConfirmationOpen(false);
    setBusy(true);

    const newBackground = background || "";

    try {
      await requestUpdateRoom(
        roomAlias,
        therapistDisplayName,
        newBackground,
        clientTimer,
      );

      setOldRoomAlias(room.roomAlias);
      setRoom(state => {
        return {
          ...state,
          roomAlias,
          therapistDisplayName,
          background: newBackground,
          clientTimer,
        };
      });
      setMessage("success");

      datadogEvent({
        category: "room_settings",
        event: "saved",
        component: "RoomSettings",
      });
    } catch (err) {
      switch (err.status) {
        case 400:
          setError(errorInvalid);
          break;
        case 409:
          setError(errorInUse);
          break;
        default:
          setError(errorInternal);
      }

      setMessage("fail");
    }

    setBusy(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Wrapper>
        <Body>
          <Content>
            <RoomSettingsNotification
              message={message}
              oldRoomAlias={oldRoomAlias}
              newRoomAlias={roomAlias}
            />
            <Panel>
              <div>
                <Header>
                  <H1>{header}</H1>
                </Header>
                <NameSettings
                  therapistDisplayName={therapistDisplayName}
                  roomAlias={roomAlias}
                  onChange={onChange}
                  error={error}
                />
              </div>
              <ClientTimerSettings
                onChange={onChange}
                clientTimer={clientTimer}
              />
              {room.countryCode === "US" ? <BAASettings /> : null}
              <BackgroundSettings
                currentBackground={background}
                onChange={b => setBackground(b)}
              />
            </Panel>
          </Content>
          <Footer>
            <Button
              onClick={event => {
                // If room Alias change, show dialog
                if (roomAlias !== room.roomAlias) {
                  setConfirmationOpen(true);
                  return;
                }
                // if not, submit
                handleSubmit(event);
              }}
              disabled={saveDisabled()}
            >
              {save}
            </Button>
          </Footer>
        </Body>
      </Wrapper>
      {confirmationOpen && (
        <ConfirmationModal
          title={confirmationTitle}
          onConfirm={handleSubmit}
          onClose={() => {
            setConfirmationOpen(false);
          }}
          maxWidth="600px"
        >
          {swapCopyVariables(confirmationBody, {
            URL: `https://${window.location.hostname}/${roomAlias}`,
          })}
        </ConfirmationModal>
      )}
    </>
  );
}

export default RoomSettings;
