import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import {
  SessionHistoryProvider,
  useSessionHistoryContext,
} from "./SessionHistoryProvider";
import { requestSessionHistory } from "../../httpapi";
import useCopy from "../../hooks/useCopy";
import { debounce } from "@sussex/react-kit/utils";
import TableWrapper from "./TableWrapper";
import ErrorAlert from "../ErrorAlert";
import NoSessionsView from "./NoSessionsView";
import EmptyResultsView from "./EmptyResultsView";
import Pagination from "../Pagination";
import { H1, Table, MobileTable } from "@sussex/react-kit/elements";
import Panel from "../Panel";
import { NameFilter, StartFilter } from "./Filters";
import loadingIcon from "../../assets/loading.svg";
import { datadogEvent } from "../../datadog";

const Time = styled.span`
  white-space: nowrap;
`;

const Header = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const DelayText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.secondary};
  margin: 10px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0;
  }
`;

const Body = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    min-width: 600px;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = () => {
  return (
    <IconWrapper>
      <Icon src={loadingIcon} alt="loading" />
    </IconWrapper>
  );
};

const PageContent = () => {
  const [
    nameText,
    dateText,
    startTimeText,
    endTimeText,
    durationText,
    errorMessage,
  ] = useCopy([
    "sessionHistory.name",
    "sessionHistory.date",
    "sessionHistory.startTime",
    "sessionHistory.endTime",
    "sessionHistory.duration",
    "sessionHistory.errorMessage",
  ]);

  const [hasSessions, setHasSessions] = useState(false);
  const debounced = useRef(null);
  const query = useRef({});

  const {
    history,
    error,
    loading,
    setHistory,
    setError,
    setLoading,
    offset,
    setOffset,
    limit,
    startFilter,
    nameFilter,
  } = useSessionHistoryContext();

  query.current = {
    offset,
    limit,
    startFilter,
    nameFilter,
  };

  useEffect(() => {
    if (debounced.current === null) {
      debounced.current = debounce(() => {
        requestSessionHistory(query.current)
          .then(response => {
            if (response.success === false) {
              setError(true);
              return;
            }
            setHistory(response);
          })
          .catch(e => {
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500);
    }
    setError(false);
    setLoading(true);
    debounced.current();
  }, [
    setError,
    setLoading,
    setHistory,
    offset,
    limit,
    startFilter,
    nameFilter,
  ]);

  useEffect(() => {
    if (!hasSessions && history && history.pagination.total > 0) {
      setHasSessions(true);
    }
  }, [history, hasSessions]);

  if (history === null) {
    return <Loading />;
  }

  if (error) {
    return <ErrorAlert message={errorMessage} />;
  }

  if (!hasSessions) {
    return <NoSessionsView />;
  }

  const emptyResult =
    (startFilter !== "" || nameFilter !== "") && history.pagination.total === 0;

  const tableData = {
    headerData: [nameText, dateText, startTimeText, endTimeText, durationText],
    filterData: [<NameFilter />, <StartFilter />, null, null, null],
    data:
      loading || emptyResult
        ? null
        : history.sessions.map(session => {
            const startDate = new Date(session.started_at * 1000);
            const endDate = new Date(session.ended_at * 1000);
            const duration = new Date(endDate - startDate);
            const durationString = duration.toISOString().substr(11, 8);
            return [
              session.display_name,
              startDate.toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
              <Time>
                {startDate.toLocaleTimeString([], {
                  timeStyle: "short",
                })}
              </Time>,
              <Time>
                {endDate.toLocaleTimeString([], {
                  timeStyle: "short",
                })}
              </Time>,
              durationString,
            ];
          }),
    footer: loading ? <Loading /> : emptyResult ? <EmptyResultsView /> : null,
  };

  const nextPage = () => {
    setOffset(parseInt(offset) + parseInt(limit));

    datadogEvent({
      category: "session_history",
      feature: "next_page",
      event: "clicked",
      component: "SessionHistory",
    });
  };

  const previousPage = () => {
    setOffset(parseInt(offset) - parseInt(limit));

    datadogEvent({
      category: "session_history",
      feature: "previous_page",
      event: "clicked",
      component: "SessionHistory",
    });
  };

  return (
    <>
      <TableWrapper mobile={true}>
        <MobileTable {...tableData} />
      </TableWrapper>
      <TableWrapper mobile={false}>
        <Table {...tableData} />
      </TableWrapper>
      {!emptyResult && (
        <Pagination
          loading={loading}
          onNext={nextPage}
          onPrevious={previousPage}
          page={Math.floor(
            (history.pagination.offset + 1) / history.pagination.limit + 1,
          )}
          totalPages={Math.ceil(
            history.pagination.total / history.pagination.limit,
          )}
        />
      )}
    </>
  );
};

const SessionHistory = () => {
  const [header, delayText] = useCopy([
    "sessionHistory.header",
    "sessionHistory.delayText",
  ]);

  return (
    <Panel>
      <Body>
        <Content>
          <Header>
            <H1>{header}</H1>
            <DelayText>{delayText}</DelayText>
          </Header>
          <SessionHistoryProvider>
            <PageContent />
          </SessionHistoryProvider>
        </Content>
      </Body>
    </Panel>
  );
};

export default SessionHistory;
