import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import ErrorBoundary from "./components/ErrorBoundary";
import TOSModal from "./components/TermsOfService/TOSModal";
import { CopyProvider } from "./providers/CopyProvider";
import { AppStateProvider } from "./providers/AppStateProvider";
import { v4 as uuidv4 } from "uuid";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.light};
    -webkit-font-smoothing: antialiased;
  }
`;

const AppContainer = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.default};
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

function App({ children }) {
  return (
    <>
      <AppContainer>
        <GlobalStyle />
        <AppStateProvider>
          <CopyProvider lang={navigator.language}>
            <ErrorBoundary>{children}</ErrorBoundary>
            <TOSModal />
          </CopyProvider>
        </AppStateProvider>
      </AppContainer>
    </>
  );
}

export default App;

export const appInstanceId = uuidv4();
