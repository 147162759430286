import { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import useNav from "../../../hooks/useNav";
import useCopy from "../../../hooks/useCopy";
import OverflowMenu from "../../OverflowMenu";
import { CSSTransition } from "react-transition-group";
import arrow from "../../../assets/arrow-back.svg";

const SLIDE_TRANSITION_TIME = 200;
const SLIDE_TRANSITION_NAME = "slidein";
const OVERLAY_TRANSITION_TIME = 500;
const OVERLAY_TRANSITION_NAME = "overlay";

const Overlay = styled.div`
  position: fixed;
  background: rgb(84, 94, 110);
  opacity: 0.4;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 1;

  &.${OVERLAY_TRANSITION_NAME}-enter {
    opacity: 0;
  }

  &.${OVERLAY_TRANSITION_NAME}-enter-active {
    opacity: 0.4;
    transition: opacity ${SLIDE_TRANSITION_TIME}ms ease;
  }

  &.${OVERLAY_TRANSITION_NAME}-exit {
    opacity: 0.4;
  }

  &.${OVERLAY_TRANSITION_NAME}-exit-active {
    opacity: 0;
    transition: opacity ${SLIDE_TRANSITION_TIME}ms ease;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  width: 345px;
  max-width: 100%;
  background: white;
  box-shadow: 10px 0px 15px rgba(31, 41, 55, 0.1);
  top: 0;
  overflow: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 1;

  &.${SLIDE_TRANSITION_NAME}-enter {
    transform: translateX(-100%);
  }

  &.${SLIDE_TRANSITION_NAME}-enter-active {
    transform: translateX(0);
    transition: transform ${SLIDE_TRANSITION_TIME}ms ease;
  }

  &.${SLIDE_TRANSITION_NAME}-exit {
    transform: translateX(0);
  }

  &.${SLIDE_TRANSITION_NAME}-exit-active {
    transform: translateX(-100%);
    transition: transform ${SLIDE_TRANSITION_TIME}ms ease;
  }
`;

const Header = styled.div`
  background: ${({ theme }) => theme.colors.light};
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 19px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: 1.5;
`;

const BackButton = styled.button`
  display: block;
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  width: 15px;
  height: 14px;
  padding: 15px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("${arrow}");
  position: absolute;
  top: 50%;
  left: 26px;
  transform: translateY(-50%);
`;
BackButton.defaultProps = {
  type: "button",
};

const Body = styled.div``;

const NavSection = styled.div`
  padding: 24px 0;
  margin: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  &:last-of-type {
    border-bottom: none;
  }
  a {
    font-family: ${({ theme }) => theme.fontSize.large};
    color: ${({ theme }) => theme.colors.default};
    line-height: 1.5;
    text-decoration: none;
    margin: 0;
    padding: 0;
  }
`;

const NavTitleLink = styled(Link)`
  display: inline-flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Icon = styled.img`
  width: 18px;
`;

const NavChildren = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 34px;
  list-style-type: none;
`;

const Child = styled.li`
  font-family: ${({ theme, isActive }) =>
    isActive ? theme.fonts.semiBold : theme.fonts.primary};
  margin-top: 16px;
  a {
    display: flex;
    align-items: center;
  }
`;

const Pill = styled.div`
  background: ${({ theme }) => theme.colors.primaryBlue};
  height: 20px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: white;
  border-radius: 20px;
  font-size: 11px;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-left: 10px;
`;

const MobileNav = ({ trackNavEvent }) => {
  const [active, setActive] = useState(false);
  const { breakpoints } = useTheme();
  const { width } = useWindowSize();
  const location = useLocation();
  const secondaryNav = useNav("secondary");
  const panelRef = useRef(null);
  const overlayRef = useRef(null);
  const [titleText, waitingRoomText, historyText, settingsText, helpText] =
    useCopy([
      "global.menu",
      "navItem.waitingRoom",
      "navItem.sessionHistory",
      "navItem.settings",
      "navItem.help",
    ]);

  // Add sessions nav items
  const nav = { ...secondaryNav };
  nav?.children?.forEach(item => {
    if (item.id === "sessions") {
      item.children = [
        {
          url: "/dashboard",
          id: "sessionsWaitingRoom",
          label: waitingRoomText,
        },
        {
          url: "/dashboard/history",
          id: "sessionsHistory",
          label: historyText,
        },
        {
          url: "/dashboard/settings",
          id: "sessionsSettings",
          label: settingsText,
        },
        { url: "/dashboard/help", id: "sessionsHelp", label: helpText },
      ];
    }
  });

  const handleClick = item => {
    trackNavEvent(item, "Header.MobileNav");
    setActive(false);
  };

  useEffect(() => {
    if (active && width >= parseInt(breakpoints.desktop)) {
      setActive(false);
    }
  }, [width, active, breakpoints.desktop]);

  return (
    <>
      <OverflowMenu type="hamburger" onClick={() => setActive(true)} />
      <CSSTransition
        in={active}
        timeout={OVERLAY_TRANSITION_TIME}
        classNames={OVERLAY_TRANSITION_NAME}
        nodeRef={overlayRef}
        unmountOnExit
      >
        <Overlay onClick={() => setActive(false)} ref={overlayRef} />
      </CSSTransition>
      <CSSTransition
        in={active}
        timeout={SLIDE_TRANSITION_TIME}
        classNames={SLIDE_TRANSITION_NAME}
        nodeRef={panelRef}
        unmountOnExit
      >
        <Wrapper ref={panelRef}>
          <Header>
            <BackButton onClick={() => setActive(false)} />
            {titleText}
          </Header>
          <Body>
            {nav?.children?.map(item => (
              <NavSection key={item.label}>
                <NavTitleLink to={item.url} onClick={() => handleClick(item)}>
                  <Icon src={item.icon} />
                  {item.label}
                </NavTitleLink>
                <NavChildren>
                  {item?.children?.map(child => (
                    <Child
                      key={child.label}
                      isActive={child.url === location.pathname}
                    >
                      <Link to={child.url} onClick={() => handleClick(child)}>
                        {child.label}{" "}
                        {child.pill && <Pill>{child.pill.label}</Pill>}
                      </Link>
                    </Child>
                  ))}
                </NavChildren>
              </NavSection>
            ))}
          </Body>
        </Wrapper>
      </CSSTransition>
    </>
  );
};

export default MobileNav;
