import { useState, useEffect } from "react";

const target =
  window.visualViewport && window.visualViewport.addEventListener
    ? window.visualViewport
    : window;

function getSize() {
  return {
    width: target === window ? target.innerWidth : target.width,
    height: target === window ? target.innerHeight : target.height,
  };
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    target.addEventListener("resize", handleResize);
    return () => {
      target.removeEventListener("resize", handleResize);
    };
  }, []);
  return windowSize;
}
