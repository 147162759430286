import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import { PageHeading, Anchor } from "@sussex/react-kit/elements";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../hooks/useCopy";
import notFoundImageXS from "../assets/notfound-xs.svg";
import notFoundImage from "../assets/notfound.svg";
import { getLoginUrl } from "../utils";
import { datadogSetPage } from "../datadog";

const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  background: white;
  width: 100%;
  height: 100vh;
`;

const HeaderImage = styled.img`
  width: 100%;
  display: none;
  margin-bottom: 15px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
    margin-bottom: 0;
  }
`;

const HeaderImageXS = styled(HeaderImage)`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const Content = styled.div`
  width: 80%;
  text-align: center;
  margin: 0 auto;

  p {
    margin: 15px auto;
    font-size: ${({ theme }) => theme.fontSize.large};
    line-height: 30px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 38%;
  }
`;

function NotFoundView() {
  const loginUrl = getLoginUrl();
  const [bodyText, headerText, loginText] = useCopy([
    "notfound.body",
    "notfound.header",
    "notfound.loginAction",
  ]);

  useEffect(() => {
    datadogSetPage("NotFound");
  }, []);

  const login = () => <Anchor href={loginUrl}>{loginText}</Anchor>;

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header type="logoOnly" />
      </HeaderWrapper>
      <HeaderImage src={notFoundImage} />
      <HeaderImageXS src={notFoundImageXS} />
      <Content>
        <PageHeading>{headerText}</PageHeading>
        <p>{swapCopyVariables(bodyText, { LOGIN_ACTION: login() })}</p>
      </Content>
    </Wrapper>
  );
}

export default NotFoundView;
