import React, { useState, useEffect } from "react";
import VideoTrack from "../VideoTrack";
import useVideoContext from "../../hooks/useVideoContext";
import styled from "styled-components";
import {
  AudioToggleButton,
  VideoToggleButton,
} from "../SessionControls/Buttons";
import ClientWaitingRoomChatButton from "../ClientWaitingRoomChatButton";
import SettingsButton from "./SettingsButton";
import Error from "./Error";
import Loading from "./Loading";
import { LocalTrackErrors } from "../../providers/VideoProvider/useLocalTracks/useLocalTracks";

const Wrapper = styled.div`
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-around;
  }
`;

const AspectRatio = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 140%;
  border-radius: 3px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 56%;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.dark};
  position: absolute;
  top: 0;
  left: 0;
  video {
    object-fit: cover;
    height: 100%;
  }
`;

const EmptyVideo = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.dark};
`;

const MediaControlsWrap = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0;
  left: ${({ isTherapist }) => (isTherapist ? "0" : "10px")};
  margin: 0 auto;
  text-align: ${({ isTherapist }) => (isTherapist ? "center" : "left")};
  height: 45px;
`;

const ChatControlWrap = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 45px;
  margin: 0 auto;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  height: 45px;
  width: 45px;
  padding-right: 10px;
  &:last-child {
    padding-right: 0;
  }
`;

const SettingsButtonWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
`;

export default function LocalVideoPreview({
  setVideo,
  showMediaControls,
  onMediaSettingsOpen,
  isTherapist,
}) {
  const [videoStarted, setVideoStarted] = useState(false);
  const { localTracks, localTrackError } = useVideoContext();
  const videoTrack = localTracks.find(track => track.name.includes("camera"));
  const showVideo =
    videoTrack && (!localTrackError || localTrackError.device === "audio");
  const showSettings =
    onMediaSettingsOpen &&
    ((!localTrackError && videoStarted) ||
      (localTrackError &&
        localTrackError.code !== LocalTrackErrors.Permissions));
  const showControls = showMediaControls && !localTrackError && videoStarted;

  useEffect(() => {
    if (!videoTrack) return;

    if (videoTrack.isStarted) {
      setVideoStarted(true);
      return;
    }

    const onVideoStart = () => {
      setVideoStarted(true);
    };
    videoTrack.on("started", onVideoStart);
    return () => {
      videoTrack.off("started", onVideoStart);
    };
  }, [videoTrack]);

  return (
    <Wrapper>
      <AspectRatio>
        <VideoWrapper>
          {showVideo ? (
            <VideoTrack setVideo={setVideo} track={videoTrack} isLocal />
          ) : (
            <EmptyVideo />
          )}
          {localTrackError && (
            <Error
              error={localTrackError}
              onMediaSettingsOpen={onMediaSettingsOpen}
            />
          )}
          {!localTrackError && !videoStarted && <Loading />}
        </VideoWrapper>
        {showSettings && (
          <SettingsButtonWrapper>
            <SettingsButton onClick={onMediaSettingsOpen} />
          </SettingsButtonWrapper>
        )}
        {showControls && (
          <MediaControlsWrap isTherapist={isTherapist}>
            <ButtonWrapper>
              <AudioToggleButton />
            </ButtonWrapper>
            <ButtonWrapper>
              <VideoToggleButton />
            </ButtonWrapper>
          </MediaControlsWrap>
        )}
        {showControls && !isTherapist && (
          <ChatControlWrap>
            <ClientWaitingRoomChatButton />
          </ChatControlWrap>
        )}
      </AspectRatio>
    </Wrapper>
  );
}
