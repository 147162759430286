import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "@sussex/react-kit/elements";
import useCopy from "../hooks/useCopy";
import logo from "../assets/pt-sessions-logo-lg.svg";
import { requestIdentityVerification } from "../httpapi";
import { getLoginUrl } from "../utils";
import { datadogSetPage, datadogEvent } from "../datadog";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  height: 100vh;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Panel = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const TherapistsPanel = styled(Panel)`
  background: ${({ theme }) => theme.colors.primaryBlue};
`;

const ClientsPanel = styled(Panel)`
  background: ${({ theme }) => theme.colors.vibrantBlue};
`;

const Logo = styled.img`
  position: relative;
  height: 50px;
  margin: 15px 0 0 15px;
  user-select: none;
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 85%;
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  color: white;
  @media (min-width: 768px) {
    width: 75%;
  }
  @media (min-width: 1024px) {
    width: 55%;
  }
`;

const TherapistsContent = styled(Content)`
  top: 30%;
`;

const ClientsContent = styled(Content)`
  top: 20%;
  @media (min-width: 768px) {
    top: 30%;
  }
`;

const Title = styled.h1`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
`;

const Message = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: normal;
  margin: 15px 0;
  font-size: 23px;
  line-height: 35px;
  @media (min-width: 480px) {
    margin: 20px 0;
    font-size: 28px;
    line-height: 40px;
  }
  @media (min-width: 768px) {
    font-size: 33px;
    line-height: 45px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const CustomButton = styled(Button)`
  height: 50px;
  color: white;
  min-width: 100px;
  max-width: 45%;
  @media (min-width: 480px) {
    min-width: 130px;
  }
`;

const LoginButton = styled(CustomButton)`
  margin-right: 10px;
  background: ${({ theme }) => theme.colors.green};
  border: 1px solid ${({ theme }) => theme.colors.green};
  &:hover {
    background: ${({ theme }) => theme.colors.darkGreen};
    border-color: ${({ theme }) => theme.colors.darkGreen};
  }
`;

const SignupButton = styled(CustomButton)`
  background: transparent;
  border: 1px solid white;
  &:hover {
    background: white;
    color: ${({ theme }) => theme.colors.default};
  }
`;

const authorize = (onAuthSuccess, onAuthFailure) => async () => {
  // request server verification
  const result = await requestIdentityVerification();

  // callback to success
  if (result.success) {
    return onAuthSuccess();
  }

  // callback with failure;
  return onAuthFailure();
};

function LandingView() {
  const [renderView, setRenderView] = useState(false);
  const [
    therapistsTitle,
    therapistsMessage,
    loginText,
    signupText,
    clientsTitle,
    clientsMessage,
  ] = useCopy([
    "landing.therapistsTitle",
    "landing.therapistsMessage",
    "landing.loginText",
    "landing.signupText",
    "landing.clientsTitle",
    "landing.clientsMessage",
  ]);

  const login = () => {
    datadogEvent({
      category: "landing",
      feature: "login",
      event: "clicked",
      component: "LandingView",
    });

    window.location = getLoginUrl();
  };

  const signup = () => {
    datadogEvent({
      category: "landing",
      feature: "signup",
      event: "clicked",
      component: "LandingView",
    });

    window.location = `${process.env.REACT_APP_SIGNUP_WEB}?src=sessions`;
  };

  useEffect(() => {
    datadogSetPage("Landing");
  }, []);

  useEffect(() => {
    authorize(
      () => {
        window.location = "/dashboard";
      },
      () => {
        setRenderView(true);
      },
    )();
  }, [setRenderView]);

  return renderView ? (
    <Wrapper>
      <TherapistsPanel>
        <Logo src={logo} />
        <TherapistsContent>
          <Title>{therapistsTitle}</Title>
          <Message>{therapistsMessage}</Message>
          <Actions>
            <LoginButton id="loginBtn" onClick={login}>
              {loginText}
            </LoginButton>
            <SignupButton id="signupBtn" onClick={signup}>
              {signupText}
            </SignupButton>
          </Actions>
        </TherapistsContent>
      </TherapistsPanel>
      <ClientsPanel>
        <ClientsContent>
          <Title>{clientsTitle}</Title>
          <Message>{clientsMessage}</Message>
        </ClientsContent>
      </ClientsPanel>
    </Wrapper>
  ) : null;
}

export default LandingView;
