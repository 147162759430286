import useInterval from "./useInterval";
import { requestListParticipants } from "../httpapi";
import { useAppStateContext, actions } from "../providers/AppStateProvider";
import { sortParticipants } from "../utils";

const usePollParticipants = (interval = 60000) => {
  const { dispatch } = useAppStateContext();

  useInterval(async () => {
    const result = await requestListParticipants();
    if (!result.success) {
      console.error("failed to fetch participants", result.error);
      return;
    }
    dispatch({
      type: actions.WAITROOM_SET_PARTICIPANTS,
      participants: sortParticipants(result.participants),
    });
  }, interval);
};

export default usePollParticipants;
