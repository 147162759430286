import React, { useState } from "react";
import styled from "styled-components";
import { H1, SubmitButton, TextInput } from "@sussex/react-kit/elements";
import TermsOfService from "../TermsOfService";
import { requestClientAuthorization } from "../../httpapi";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import useCopy from "../../hooks/useCopy";
import useVideoContext from "../../hooks/useVideoContext";
import { useAppStateContext, actions } from "../../providers/AppStateProvider";
import { datadogEvent } from "../../datadog";

const { WEBSOCKET_SET_CONNECT, WAITROOM_SET_DISPLAYNAME } = actions;

const FormWrapper = styled.form`
  display: grid;
  width: 100%;
  grid-template-columns: 0fr;
  grid-template-rows: 0fr 0fr;
`;

const Header = styled.div`
  grid-column: 2 / span 1;
  grid-row: span 1;
  margin-bottom: 15px;
  line-height: 1;
`;

const Prompt = styled.p`
  grid-column: 2 / span 1;
  grid-row: span 1;
  font-size: 14px;
  margin: 0 0 20px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 17px;
  }
`;

const InputWrapper = styled.div`
  grid-column: span 2;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 2 / span 1;
  }
`;

const FormItemWrapper = styled.div`
  margin-bottom: 20px;
`;

function CheckInView({ video, canvasRef, onVideoError, onConnectionError }) {
  const { room } = useWaitingRoomContext();
  const { dispatch } = useAppStateContext();
  const { isAcquiringLocalTracks, localTrackError } = useVideoContext();
  const [busy, setBusy] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [
    tosPromptText,
    willkommenText,
    enterNameText,
    checkingInText,
    joinSessionText,
  ] = useCopy([
    "checkinscreen.tosPrompt",
    "checkinscreen.welcome",
    "checkinscreen.enterName",
    "checkinscreen.checkingIn",
    "checkinscreen.joinSession",
  ]);

  const handleDisplayNameChange = e => {
    setDisplayName(e.target.value);
  };

  const disabled = () =>
    !displayName.match(/[\p{L}]{2,32}/u) ||
    isAcquiringLocalTracks ||
    localTrackError ||
    busy;

  const renderButtonText = () => (busy ? checkingInText : joinSessionText);

  // Render a video frame and capture the client's photo so the therapist can
  // visually identify the client.
  const capturePhoto = (video, canvas) => {
    // If videoWidth or videoHeight not set, trigger video error.
    if (!video || !video.videoWidth || !video.videoHeight) {
      throw new Error("video");
    }

    const ctx = canvas.getContext("2d");

    var resizeCanvas = document.createElement("canvas");
    var resizeContext = resizeCanvas.getContext("2d");

    // Compress photo to 1px and measure its color. If the combined rgb value or
    // the alpha value is 0, the video stream has a problem or the camera is
    // covered.
    canvas.width = canvas.height = 1;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const compressed = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const color = compressed.data;
    if (color[0] + color[1] + color[2] === 0 || color[3] === 0) {
      throw new Error("video");
    }

    const maxWidth = 200;
    const maxHeight = 200;
    let ratio = 1;
    if (video.videoWidth > maxWidth) {
      ratio = maxWidth / video.videoWidth;
    } else if (video.videoHeight > maxHeight) {
      ratio = maxHeight / video.videoHeight;
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    resizeCanvas.width = video.videoWidth * ratio;
    resizeCanvas.height = video.videoHeight * ratio;
    resizeContext.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      resizeCanvas.width,
      resizeCanvas.height,
    );

    return resizeCanvas.toDataURL("image/png");
  };

  const checkin = async (roomAlias, name, photo) => {
    const succeeded = await requestClientAuthorization(roomAlias, name, photo);
    if (!succeeded) {
      throw new Error("connection");
    }
    dispatch({
      type: WEBSOCKET_SET_CONNECT,
      connect: true,
    });
  };

  // handle the button submission and orchestrate the checkin steps.
  const handleSubmit = async evt => {
    evt.preventDefault();
    setBusy(true);

    datadogEvent({
      category: "waiting_room",
      feature: "checkin",
      event: "clicked",
      component: "ClientCheckin.CheckInView",
    });

    try {
      const photo = capturePhoto(video, canvasRef.current);
      await checkin(room.roomAlias, displayName, photo);

      datadogEvent({
        category: "waiting_room",
        event: "checked_in",
        component: "ClientCheckin.CheckInView",
      });

      dispatch({
        displayName,
        type: WAITROOM_SET_DISPLAYNAME,
      });
    } catch (err) {
      console.error("Error Checking In", err);
      if (err.message === "video") {
        onVideoError();
      } else {
        onConnectionError();
      }
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <Header>
        <H1>{willkommenText}</H1>
      </Header>
      <Prompt>{tosPromptText}</Prompt>
      <InputWrapper>
        <FormItemWrapper>
          <TextInput
            onChange={handleDisplayNameChange}
            placeholder={enterNameText}
            maxLength="50"
            autoComplete="name"
          />
        </FormItemWrapper>
        <FormItemWrapper>
          <TermsOfService />
        </FormItemWrapper>
        <FormItemWrapper>
          <SubmitButton id="checkinClient" disabled={disabled()} full>
            {renderButtonText()}
          </SubmitButton>
        </FormItemWrapper>
      </InputWrapper>
    </FormWrapper>
  );
}

export default CheckInView;
