import React, { useState, useEffect } from "react";
import Modal, { Portal } from "../Modal";
import styled from "styled-components";
import { Button } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import { datadogEvent } from "../../datadog";

const Body = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 1.5;

  p {
    margin: 40px 0;
  }

  p:first-of-type,
  p:last-of-type {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 17px;
  }

  ol > li {
    margin-bottom: 30px;
  }

  ol li li {
    margin-bottom: 10px;
  }

  ol li ol {
    margin-top: 10px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function TOSModal() {
  const [showModal, setShowModal] = useState(false);
  const [closeText, termText, title] = useCopy([
    "tos.close",
    "tos.terms",
    "tos.title",
  ]);

  const open = () => {
    setShowModal(true);

    datadogEvent({
      category: "waiting_room",
      feature: "tos",
      event: "opened",
      component: "TermsOfService.TOSModal",
    });
  };

  const close = () => {
    setShowModal(false);

    datadogEvent({
      category: "waiting_room",
      feature: "tos",
      event: "closed",
      component: "TermsOfService.TOSModal",
    });
  };

  useEffect(() => {
    // This event is triggered from the "Show details"
    // button of the cookiebot banner, and from the
    // TermsOfService component.
    document.addEventListener("tos", open);
    return () => document.removeEventListener("tos", open);
  }, []);

  const renderFooter = () => {
    return (
      <FooterWrapper>
        <Button onClick={close} variant="blue">
          {closeText}
        </Button>
      </FooterWrapper>
    );
  };
  return (
    <>
      {showModal && (
        <Portal>
          <Modal title={title} close={close} footer={renderFooter()}>
            <Body dangerouslySetInnerHTML={{ __html: termText }}></Body>
          </Modal>
        </Portal>
      )}
    </>
  );
}
